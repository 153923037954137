@import 'styles/theme';

.GlBackground {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;

  &.imageIsLoaded {
    opacity: 1;
  }
}

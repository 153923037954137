@use 'sass:math';
@import 'styles/theme';

.PushCover {
  width: 100%;
  overflow: hidden;
  @include switchDesktopMobileClasses('md');
  margin-top: theme($spacings, 50);
  margin-bottom: theme($spacings, 50);

  @include from('md') {
    margin-top: theme($spacings, 60);
    margin-bottom: theme($spacings, 60);
  }

  &.reversed {
    .image.big {
      @include from('md') {
        grid-column: 13 / -1;
      }
    }

    .content {
      @include from('md') {
        grid-column: 1 / 13;
      }
    }
  }

  .image {
    &.big {
      grid-row: 1;
      grid-column: 1 / -1;
      display: block;

      @include from('md') {
        grid-row: auto;
        grid-column: 1 / -13;
      }
    }

    &.small {
      @include applyColumns(
        (
          'propertyName': 'width',
          'mobile': 12,
          'desktop': 4,
        )
      );
    }
  }

  .content {
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 1 / -1;
    padding-top: theme($spacings, 60);
    padding-bottom: theme($spacings, 60);

    @include applyColumns(
      (
        'propertyName': 'padding-left',
        'mobile': 1,
        'desktop': 1,
      )
    );

    @include applyColumns(
      (
        'propertyName': 'padding-right',
        'mobile': 1,
        'desktop': 1,
      )
    );

    @include from('md') {
      grid-column: 13 / -1;
      padding-top: theme($spacings, 100);
      padding-bottom: theme($spacings, 100);
    }

    .subtitle {
      margin-bottom: theme($spacings, 10);
    }

    .title {
      text-transform: uppercase;
      position: relative;
      width: 100%;
      word-break: break-word;
      margin-bottom: theme($spacings, 60);
    }

    .cta {
      margin-top: theme($spacings, 35);

      @include from('md') {
        margin-top: theme($spacings, 80);
      }
    }
  }

  .ratio {
    @include applyPercentRatios(math.div(200, 290));
  }
}

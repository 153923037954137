@use 'sass:math';
@import 'styles/theme';

.ArticleCard {
  position: relative;
  user-select: none;

  .images {
    position: relative;
    display: block;

    .image,
    .imageHover {
      height: auto;
    }

    + .texts {
      margin-top: theme($spacings, 20);
    }
  }

  .imageHover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .texts {
    &.text-align {
      &-left {
        text-align: left;
      }

      &-center {
        text-align: center;
      }

      &-right {
        text-align: right;
      }
    }

    .name,
    .label {
      margin-top: theme($spacings, 10);
    }
  }

  .pin {
    z-index: 2;
    position: absolute;
    top: theme($spacings, 10);
    right: theme($spacings, 10);
  }

  .ratio {
    @include applyPercentRatios(math.div(422, 561));
  }
}
